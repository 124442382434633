import React from "react";

import Records from "../components/Records";

function SeeItPage() {
    return (
        <>
            <h1 className="title">See It</h1>
            <Records />
        </>
    )
}

export default SeeItPage